import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Helmet from 'react-helmet';

import styled from 'styled-components';

import '../globalStyles.css';
import { generateGradientSteps, bubbleSvg } from '../helpers';
import { teal } from '../colors';
import Layout from '../components/Layout';

const BlogContainer = styled.div`
  margin-bottom: 10%;
  margin-top: 5%;
`;

const BlogPostContainer = styled.div`
  max-width: 1920px;
`;

const BlogPostHeader = styled.header`

`;

const BlogHeaderImage = styled.div`
  position: relative;
  margin-left: 200px;
  width: 75%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("${(props) => props.url}");

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
    height: 100%;
    background:
      linear-gradient(
        -90deg,
        ${generateGradientSteps('hsla', [0, 0, 100, 1], [0, 0, 100, 0], ['', '%', '%', ''])}
      );

      @media (max-width: 550px) {
        display: none;
      }
  }

  @media (max-width: 1350px) {
    margin-left: 150px;
  }

  @media (max-width: 1023px) {
    margin-left: 100px;
  }

  @media (max-width: 850px) {
    margin-left: 50px;
  }

  @media (max-width: 550px) {
    width: 85%;
    margin-left: 20px;
  }
`;

const PaddingHack = styled.div`
  max-width: 100%;
  height: 0;
  padding-top: 37.5%;
`;

const PublishDateText = styled.div`
  margin: 1% 0 0 200px;
  color: var(--color-teal);
  font-size: 17px;

  @media (max-width: 1350px) {
    margin: 1% 0 0 150px;
    font-size: 16px;
  }

  @media (max-width: 1023px) {
    margin: 1% 0 0 100px;
  }

  @media (max-width: 850px) {
    margin: 1% 0 0 50px;
  }

  @media (max-width: 550px) {
    font-size: 14px;
    margin: 1% 0 0 20px;
  }
`;

const BlogText = styled.div`
  margin: 2% 20% 0 200px;
  color: var(--color-dark-grey);

  & h1 {
    font-size: 55px;

    @media (max-width: 1620px) {
      font-size: 45px;
    }

    @media (max-width: 1350px) {
      font-size: 35px;
    }

    @media (max-width: 1023px) {
      font-size: 30px;
    }

    @media (max-width: 550px) {
      font-size: 23px;
    }
  }

  @media (max-width: 1350px) {
    margin: 2% 25% 0 150px;
  }

  @media (max-width: 1023px) {
    margin: 2% 25% 0 100px;
  }

  @media (max-width: 850px) {
    margin: 2% 15% 0 50px;
  }

  @media (max-width: 550px) {
    margin: 2% 10% 0 20px;
  }
`;

const BlogContent = styled.main`
  margin: 3% 30% 0 200px;
  color: var(--color-dark-grey);
  text-align: justify;
  text-align-last: left;

  @media (max-width: 1350px) {
    margin: 3% 20% 0 150px;
  }

  @media (max-width: 1023px) {
    margin: 3% 20% 0 100px;
  }

  @media (max-width: 850px) {
    margin: 3% 15% 0 50px;
  }

  @media (max-width: 550px) {
    margin: 3% 10% 0 20px;
  }

  & > * {
    line-height: 1.5em;
  }

  & p {
    margin-bottom: 1.25em;
    margin-bottom: 1.25em;
    font-size: 17px;

    @media (max-width: 1350px) {
      font-size: 16px;
    }

    @media (max-width: 550px) {
      font-size: 14px;
    }
  }

  & img {
    width: 80%;

    @media (max-width: 550px) {
      width: 100%;
    }
  }

  & img[style*="float"] {
    margin-right: 32px !important;
    border-radius: 4px;
  }

  & a {
    color: var(--color-dark-grey);
    text-decoration: none;
    transition: all ease 0.3s;
    background-size: auto 175%;
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.6),
      0 4px 0 -3px rgba(52, 191, 201, 0.6),
      inset 0 0 0 0 rgba(52, 191, 201, 0.6);
  }
  
  & a:hover {
    background-position-y: 105%;
    color: var(--color-white);
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.8),
      0 0px 0 2px rgba(52, 191, 201, 0.8),
      inset 0 -1.5em 0 0 rgba(52, 191, 201, 0.8);
  }

  & h2 {
    font-size: 26px;
    margin-bottom: 2%;
    margin-top: 8%;
    text-align: left;
    font-weight: 700;

    @media (max-width: 1350px) {
      font-size: 22px;
      line-height: normal;
    }

    @media (max-width: 1023px) {
      font-size: 20px;
    }

    @media (max-width: 550px) {
      font-size: 17px;
    }
  }

  & h2 strong {
    color: var(--color-teal);
  }

  & h3 {
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 0.5em;
    margin-top: 1.25em;

    @media (max-width: 1350px) {
      font-size: 16px;
    }

    @media (max-width: 550px) {
      font-size: 14px;
    }

  }

  & li {
    list-style: none;
    position: relative;
    margin-bottom: 16px;
    padding-left: 20px;
    text-align: left;

    @media (max-width: 1023px) {
      margin-bottom: 12px;
    }
  }

  & ul {
    padding-left: 0;

    & li {
      background-image: ${bubbleSvg(teal, 0)};
      background-size: 10px;
      background-position: 20px 6px;
      background-repeat: no-repeat;
      padding-left: 61px;
      font-size: 17px;

      @media (max-width: 1350px) {
        font-size: 16px;
      }

      @media (max-width: 550px) {
        font-size: 14px;
      }
    }

  }

  & ol {
    counter-reset: ol;

    > li {
      counter-increment: ol;
      font-size: 17px;

      @media (max-width: 1350px) {
        font-size: 16px;
      }

      @media (max-width: 550px) {
        font-size: 14px;
      }
    }

    > li::before {
      content: counter(ol, decimal-leading-zero);
      position: absolute;
      display: inline-block;
      z-index: -1;
      right: 100%;
      top: 0;
      font-weight: 700;
      color: var(--color-dark-grey);
      margin-right: 0.8em;
    }
  }

  & iframe {
    max-width: 100%;
  }
  
`;

export default class BlogPosts extends Component {
  static propTypes = {
    pageContext: PropTypes.object,
    data: PropTypes.object,
    currentPath: PropTypes.string,
    isPreview: PropTypes.bool,
  };

  static defaultProps = {
    currentPath: '',
  };

  renderContent(post) {
    const { pageContext } = this.props;
    const id = post?.id || Math.random();
    const title = post?.frontmatter?.title || 'TITLE NOT FOUND';
    const html = post?.body || null;
    const publishDate = post?.frontmatter?.publishDate || Date.now();
    const image = post?.frontmatter?.image || '';

    return (
      <BlogPostContainer key={id}>
        <BlogPostHeader>
          <BlogText>
            <h1>{title}</h1>
          </BlogText>
          <BlogHeaderImage url={image}><PaddingHack /></BlogHeaderImage>
          <PublishDateText>{moment(publishDate).format(pageContext.dateFormat)}</PublishDateText>
        </BlogPostHeader>
        <BlogContent>{ html ? <MDXRenderer>{html}</MDXRenderer> : (post?.comp || <span />)}</BlogContent>
      </BlogPostContainer>
    );
  }

  render() {
    const { pageContext, data, isPreview } = this.props;
    const post = data?.mdx || {};

    let canonicalLinkPrefix;

    if (pageContext.locale === 'en') {
      canonicalLinkPrefix = 'https://presono.com/en';
    } else {
      canonicalLinkPrefix = 'https://presono.com';
    }

    return isPreview ? this.renderContent(post) : (
      <Layout currentPath={pageContext.originalPath}>
        <Helmet>
          <title>{`${data?.mdx?.frontmatter?.title} | presono`}</title>
          <meta
            name="description"
            content={data?.mdx?.frontmatter?.description}
          />

          <link rel="canonical" href={canonicalLinkPrefix + pageContext.originalPath} />

          <meta property="og:title" content={data?.mdx?.frontmatter?.title} />
          <meta property="og:description" content={data?.mdx?.frontmatter?.description} />
          <meta property="og:image" content={`https://presono.com${data?.mdx?.frontmatter?.image}`} />
          <meta name="twitter:title" content={data?.mdx?.frontmatter?.title} />
          <meta name="twitter:description" content={data?.mdx?.frontmatter?.description} />
          <meta name="twitter:image" content={`https://presono.com${data?.mdx?.frontmatter?.image}`} />
        </Helmet>
        <BlogContainer tabIndex="-1">
          { this.renderContent(post) }
        </BlogContainer>
      </Layout>
    );
  }
}

export const query = graphql`
query BlogPostQuery($date: Date!, $locale: String!) {
  mdx(
    fields: { locale: { eq: $locale } }
    frontmatter: { publishDate: { eq: $date } }
  ) {
    body
    frontmatter {
      publishDate
      title
      description
      image
    }
    slug
  }
}
`;
